.s-showreell {
  --borderPrimary: var(--borderNeutral);
}

.s-showreell__body {
  position: relative;
}

.s-showreell__content {
  position: relative;
  height: 37.375rem;
  width: 100%;
}

.s-showreell__btn {
  position: absolute;
  bottom: 4.5625rem;
  left: 19.375rem;
}

.s-showreell__content-arrows {
  margin-top: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.75rem;
}

.s-showreell__content-arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--borderPrimary);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: transparent;
  -webkit-transition-property: colors, outline;
  transition-property: colors, outline;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.s-showreell-content__slide {
  pointer-events: none;
  position: absolute;
  inset: 0px;
  display: inline-block;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 1000ms;
  transition-duration: 1000ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.s-showreell-content__slide.active {
  pointer-events: auto;
  opacity: 1;
}

.s-showreell-content__slide img,
.s-showreell-content__slide video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.s-showreell-content__pagination {
  position: absolute;
  bottom: 1rem;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  row-gap: 0.125rem;
}

.s-showreell-content__pagination button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-width: 1px;
  border-style: solid;
  border-color: var(--commonWhite);
  padding: 0.25rem;
}

.s-showreell-content__pagination button.active span {
  opacity: 1;
}

.s-showreell-content__pagination button span {
  display: block;
  height: 0.5rem;
  min-width: 2rem;
  background-color: var(--commonWhite);
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.s-showreell-content__arrow-next {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (max-width: 79.9988em) {
  .s-showreell__content {
    height: 24rem;
  }

  .s-showreell-content__slide img,
  .s-showreell-content__slide video {
    -o-object-fit: contain;
    object-fit: contain;
  }
}

@media (max-width: 61.9988em) {
  .s-showreell__content {
    height: 20rem;
  }
}

@media (max-width: 47.9988em) {
  .s-showreell__content {
    height: 18rem;
  }

  .s-showreell__btn {
    bottom: auto;
    top: 0.75rem;
    left: 0.5rem;
  }
}

@media (max-width: 35.998em) {
  .s-showreell__content {
    height: 13rem;
  }
}

@media (prefers-color-scheme: dark) {
  .s-showreell {
    --borderPrimary: var(--borderNeutral);
  }
}

@media (any-hover: hover) {
  .s-showreell__content-arrow:hover {
    outline-color: var(--borderPrimary);
  }
}